import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`11. YOUR RIGHTS`}</h2>
    <p>{`Where the GDPR applies, in certain circumstances and subject to data processing agreements,
You have rights in relation to the personal information We hold about You. We set out below an
outline of those rights and how to exercise those rights. Please note that We will require You
to verify Your identity before responding to any requests to exercise Your rights by providing
details only known to the account holder. To exercise any of Your rights, please contact Us at the bottom
of this page. We will respond to Your request within a reasonable timeframe. Please note that
for each of the rights below We may have valid legal reasons to refuse Your request, in such
instances We will let You know if that is the case. Please note that if We have obtained or received
personal information about You from a third party for the purpose of processing that data,
any requests to exercise Your rights with respect to that data should be directed to that third party.`}</p>
    <p>{`We will honor and support any instructions they provide Us with respect to Your personal information.`}</p>
    <h3>{`11.1 ACCESS`}</h3>
    <p>{`You have the right to know whether We process personal information about You, and if We do,
to access data We hold about You and certain information about how We use it and who We share it with.`}</p>
    <h3>{`11.2 PORTABILITY`}</h3>
    <p>{`You have the right to receive a subset of the personal information You provide Us if We process it
on the legal bases of Our contract with You or with Your consent in a structured, commonly used
and machine-readable format and a right to request that We transfer such personal information to
another party. If You wish for Us to transfer the personal information to another party,
please ensure You detail that party and note that We can only do so where it is technically feasible.
We are not responsible for the security of the personal information or its processing once received
by the third party.`}</p>
    <h3>{`11.3 CORRECTION`}</h3>
    <p>{`You have the right to require Us to correct any personal information held about You that is inaccurate
and have incomplete data completed. Where You request correction, please explain in detail why You
believe the personal information We hold about You to be inaccurate or incomplete so that We can
assess whether a correction is required. Please note that while We assess whether the personal
information We hold about You is inaccurate or incomplete, You may exercise Your right to restrict
Our processing of the applicable data as described below.`}</p>
    <h3>{`11.4 ERASURE`}</h3>
    <p>{`You may request that We erase the personal information We hold about You in the following
circumstances:`}</p>
    <ul>
      <li parentName="ul">{`where You believe it is no longer necessary for Us to hold the personal information;`}</li>
      <li parentName="ul">{`We are processing it on the basis of Your consent and You wish to withdraw Your consent;`}</li>
      <li parentName="ul">{`We are processing Your data on the basis of Our legitimate interest and You object to such processing;`}</li>
      <li parentName="ul">{`You no longer wish Us to use Your data to send You marketing; or`}</li>
      <li parentName="ul">{`You believe We are unlawfully processing Your data.`}</li>
    </ul>
    <p>{`Please provide as much detail as possible on Your reasons for the request to assist Us in
determining whether You have a valid basis for erasure.`}</p>
    <h3>{`11.5 RESTRICTION OF PROCESSING TO STORAGE ONLY`}</h3>
    <p>{`You have a right to require Us to stop processing the personal information We hold about You
other than for storage purposes in the following circumstances:`}</p>
    <ul>
      <li parentName="ul">{`You believe the personal information is not accurate for the period it takes for Us to
verify whether the data is accurate;`}</li>
      <li parentName="ul">{`We wish to erase the personal information as the processing We are doing is unlawful
but You want Us to simply restrict the use of that data;`}</li>
      <li parentName="ul">{`We no longer need the personal information for the purposes of the processing but You
require Us to retain the data for the establishment, exercise or defense of legal claims; or`}</li>
      <li parentName="ul">{`You have objected to Us processing personal information We hold about You on the basis
of Our legitimate interest and You wish Us to stop processing the personal information while
We determine whether there is an overriding interest in Us retaining such personal information.`}</li>
    </ul>
    <h3>{`11.6 OBJECTION`}</h3>
    <p>{`You have the right to object to Our processing of data about You and We will consider Your request.
Please provide Us with detail as to Your reasoning so that We can assess whether there is a compelling
overriding interest in Us continuing to process such data or We need to process it in relation to
legal claims. You also have the right, at any time, to object to Our processing of data about You
in order to send You marketing, including where We build profiles for such purposes and We will
stop processing the data for that purpose.`}</p>
    <h3>{`11.7 WITHDRAWAL OF CONSENT`}</h3>
    <p>{`Where You have provided Your consent to Us processing Your personal data, You can withdraw Your
consent at any time by contacting Us at the bottom of this page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      