import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`9. RETENTION OF PERSONAL INFORMATION`}</h2>
    <p>{`We retain Your personal information to provide Services to You and as otherwise necessary to
comply with Our legal obligations, resolve disputes, and enforce Our agreements. We will retain
Your personal information for no more than seven years following the the date on
which You terminate Your use of the Services, unless We are otherwise required
by law or regulation to retain Your personal information for longer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      