import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`5. SHARING OF INFORMATION`}</h2>
    <p>{`As further described below, We will only share certain personal information as follows:`}</p>
    <h3>{`5.1 SHARING WITH PARTNERS`}</h3>
    <p>{`When We share Your personal information with certain third-party partners, including marketing and
advertising partners, that information includes Your name, email address and other information
enabling partners to:`}</p>
    <ul>
      <li parentName="ul">{`Assist You in using Our Services,`}</li>
      <li parentName="ul">{`Contact You with offers, services or products that may be of interest to You, and`}</li>
      <li parentName="ul">{`Provide You with their products or services.`}</li>
    </ul>
    <p>{`Our partners may use cookies and similar technologies in order to provide You advertising based
upon Your browsing activities and interests. If You are located in a jurisdiction where such
sharing requires Your permission, We will only do so with Your consent. Please note that if You
access Our Services through a tool that hides Your location, such as through a virtual private
network, You may not receive Our request for permission because We were not able to identify You
as being located in a jurisdiction where Your permission is required.`}</p>
    <p>{`Further, Our partners are prohibited from using Your contact information for any purpose beyond
those set forth above without Your consent. We will not provide Our partners with Your payment or
credit card information. In the event We collect information from You in connection with an offer that
is jointly presented by Us and a partner, We will let You know who is collecting the information
and whose privacy notice applies, as well as any options You may have regarding use of Your
information.`}</p>
    <h3>{`5.2 SHARING WITH THIRD PARTY SERVICE PROVIDERS AND VENDORS`}</h3>
    <p>{`HostJane may enter into contracts with carefully selected third parties to assist Us in servicing
You (for example, providing You with customer service, fraud detection and deterrence or access
to advertising assets and providing Us with information technology and storage services) or to
assist Us in Our own marketing and advertising activities (including providing Us with analytic
information and search engine optimization services). Our contracts with such third parties prohibit
them from using any of Your personal information for any purpose beyond the purpose for which it
was shared.`}</p>
    <p>{`If You purchase a product or service from a third party through HostJane, We will pass as much as is
practical of Your personal information to such third party in order for them to fulfill Your order.`}</p>
    <p>{`We do not share Your payment or credit card information with any third party.`}</p>
    <p>{`We may share non-personal information with certain third parties, including the media,
industry observers, marketing and advertising partners, vendors, customers, potential customers
or partners. For example, We disclose mobile search trends, email open rates by industry,
campaign best practices or the number of users that have been exposed to, or clicked on,
Our websites or evaluated or purchased Our products and Services.`}</p>
    <h3>{`5.3 CORPORATE REORGANIZATIONS`}</h3>
    <p>{`If We are involved in a merger, acquisition, a sale of all or a substantial portion of Our assets,
or other similar sale transaction, Your information will be transferred as part of that transaction.
We will notify You by email and/or a prominent notice on Our website of any such transfer and any
choices You may have regarding Your information.`}</p>
    <h3>{`5.4 AUTHORIZED USERS`}</h3>
    <p>{`All users authorized by You to have access to Your account can view personal information stored in
the account. A primary account holder can view personal information saved in subaccounts to which
they have authorized access. We share information about authorized users only for legitimate purposes
consistent with this privacy policy, including servicing Your account and marketing products and
Services to You.`}</p>
    <h3>{`5.5 LEGAL PROCESS`}</h3>
    <p>{`If legally required to do so, or if We have a good faith belief that such disclosure is reasonably
necessary, We may disclose Your personal information to courts of law, public authorities
(including to meet national security or law enforcement requirements), and other relevant third
parties, such as internet service providers, to conduct an investigation, respond to a third party
or law enforcement subpoena or court order, to bring legal action, prevent harm to others or pursue
other relief when You or a third party are or may be:`}</p>
    <ul>
      <li parentName="ul">{`Violating Our terms and conditions of use;`}</li>
      <li parentName="ul">{`Causing injury or other harm to, or otherwise violating the property or other legal rights, of Us, other users, or third parties; or`}</li>
      <li parentName="ul">{`Violating federal, state, local, or other applicable law.`}</li>
    </ul>
    <p>{`This disclosure can include transferring Your information to the U.S. and other countries outside
the European Economic Area. To the extent We are legally permitted to do so, We will notify You
in the event that We are required to provide Your personal information to third parties in connection
with a subpoena.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      