import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`4. HOW WE USE YOUR INFORMATION`}</h2>
    <p>{`We use the information We collect, both on its own and combined with any other information We
collect about You, for the following purposes:`}</p>
    <ul>
      <li parentName="ul">{`To provide the requested Services to You;`}</li>
      <li parentName="ul">{`To provide You with useful content;`}</li>
      <li parentName="ul">{`To ensure the proper functioning of Our Services`}</li>
      <li parentName="ul">{`To offer and improve Our Services;`}</li>
      <li parentName="ul">{`To provide You with requested information or technical support`}</li>
      <li parentName="ul">{`To facilitate Your movement through Our websites or Your use of Our Services;`}</li>
      <li parentName="ul">{`To do a better job of advertising and marketing Our Services (subject to Your consent where required by applicable law);`}</li>
      <li parentName="ul">{`To advertise and market third party products and services (subject to Your consent where required by applicable law)`}</li>
      <li parentName="ul">{`To diagnose problems with Our servers or Our Services;`}</li>
      <li parentName="ul">{`In connection with Our security and compliance programs;`}</li>
      <li parentName="ul">{`To administer Our websites;`}</li>
      <li parentName="ul">{`To communicate with You;`}</li>
      <li parentName="ul">{`To target prospective customers with Our products or Services (subject to Your consent where required by applicable law);`}</li>
      <li parentName="ul">{`To assist Us in offering You a personalized experience or otherwise tailor Our Services to You; and`}</li>
      <li parentName="ul">{`As otherwise described in this privacy notice.`}</li>
    </ul>
    <p>{`We also use the information We receive to produce reports on trends and statistics, such as mobile search trends,
email open rates by industry, campaign best practices or the number of users that have been exposed to,
or clicked on, Our websites or evaluated or purchased Our products or services.`}</p>
    <p>{`Payment information that You provide to Us, including credit card data, will only be used to facilitate payment
for the Services. We also use recorded support conversations for quality control purposes, to train Our
employees and for Our own protection.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      