import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`General Terms of Service`}</h2>
    <p>{`Welcome to HostJane.com (“the Service”).`}</p>
    <p>{`These Terms of Service (the "Agreement") are an agreement between HostJane, Inc.
("HostJane", "We", "Us", or "Our") including HostJane, Inc.’s subsidiaries, affiliates, divisions,
contractors and all data sources and suppliers and You ("User", "You" or "Your").`}</p>
    <p>{`This Agreement sets forth the general terms and conditions of Your use of the products
and Services made available by HostJane and of the `}<a parentName="p" {...{
        "href": "https://www.hostjane.com"
      }}>{`HostJane.com website`}</a>{` (collectively, the "Services" or "Site").
By using the Services, You agree to be bound by this Agreement. If You do not agree to abide by
the terms of this Agreement, You are not authorized to use or access the Services.`}</p>
    <h2>{`1. Additional Policies and Agreements`}</h2>
    <p><strong parentName="p">{`1.1`}</strong>{` Use of the Services is also governed by the following policies, which are incorporated
by reference. By using the Services, You also agree to the terms of the following policies.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.hostjane.com/legal/marketplace-terms/"
        }}>{`Marketplace Rules`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.hostjane.com/legal/hosting-terms/"
        }}>{`Web Hosting Terms`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.hostjane.com/legal/use-policy/"
        }}>{`Acceptable Use Policy`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.hostjane.com/legal/privacy-policy/"
        }}>{`Privacy Policy`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.hostjane.com/legal/gdpr-privacy-notice/"
        }}>{`GDPR Data Processing Agreement`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.hostjane.com/legal/cookie-policy/"
        }}>{`Cookies Policy`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.hostjane.com/legal/sla/"
        }}>{`Service Level Agreement`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.hostjane.com/legal/ihra-antisemitism/"
        }}>{`Fighting Antisemitism`}</a></li>
    </ol>
    <p><strong parentName="p">{`1.2`}</strong>{` Additional terms and Site rules and procedures may also apply to Our Services, which may be found in relevant
areas of HostJane.com and should be taken as incorporated into this Agreement. For example, if a Discord server or forum is
offered for freelancers on HostJane then any rules applicable to posting and commenting on other people's threads
published inside the forum should be taken as being part of this Agreement.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      