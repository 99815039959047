import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`6. SECURITY`}</h2>
    <p>{`The transmission of information via the internet, email or text message is not completely secure.
Although We will do Our best to protect Your personal information, We cannot guarantee the security
of Your information transmitted through the Services or over email; any transmission is at Your own
risk. Once We have received Your information, We will take appropriate technical and organizational
measures to safeguard Your personal information against loss, theft and unauthorized use, access or
modification. When We collect financial account information, such as credit card numbers, We protect
its transmission through the use of encryption such as the Transport Layer Security (TLS) protocol.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      