import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`3. INFORMATION WE COLLECT FROM YOU`}</h2>
    <p>{`In the course of Your use of the Services, We obtain the following information about You as
described below. We collect this data for the purposes described under “How We Use Your Information”.`}</p>
    <h3>{`3.1 INFORMATION YOU VOLUNTARILY PROVIDE TO US`}</h3>
    <p>{`HostJane collects the following information from You when You provide it to Us:`}</p>
    <ul>
      <li parentName="ul">{`Information You provide prior to any registration process, such as Your email
when You provide it to Us;`}</li>
      <li parentName="ul">{`Information that You provide during any registration process, including in connection
with a co-branded offer (such as Your name, company name, email address, phone number,
billing address or credit card information, geographic location and industry);`}</li>
      <li parentName="ul">{`Information You provide, when You chat or email Us (for support or otherwise) or when
You use Our products or Services; and`}</li>
      <li parentName="ul">{`Payment information, including credit card data that You provide to Us (by way of Our
Services or otherwise) when You purchase some of Our products and Services.`}</li>
    </ul>
    <h3>{`3.2 INFORMATION WE COLLECT ABOUT YOUR USAGE AND DEVICES`}</h3>
    <p>{`We collect data relating to Your online activity on Our websites, including the following:`}</p>
    <ul>
      <li parentName="ul">{`IP address;`}</li>
      <li parentName="ul">{`Browser type and version;`}</li>
      <li parentName="ul">{`Type of device You use, operating system version, and device identifier;`}</li>
      <li parentName="ul">{`Geographic location;`}</li>
      <li parentName="ul">{`Pages You view;`}</li>
      <li parentName="ul">{`How You got to Our Services and any links You click on to leave Our Services;`}</li>
      <li parentName="ul">{`When You update Your information, communicate with Us, or order new Services;`}</li>
      <li parentName="ul">{`Metadata about Your use of Our Services and emails We send to You (including clicks and opens);`}</li>
      <li parentName="ul">{`Metadata about how Your customers, contacts, and users interact with the Services We provide You;`}</li>
      <li parentName="ul">{`Your interactions with any videos We offer;`}</li>
      <li parentName="ul">{`Issues You encounter requiring Our support or assistance; and`}</li>
      <li parentName="ul">{`Any device or other method of communication You use to interact with the Services.`}</li>
    </ul>
    <p>{`We store the data We collect in a variety of places within Our infrastructure, including system
log files, back end databases and analytics systems.`}</p>
    <h3>{`3.3 INFORMATION WE ACQUIRE FROM SOCIAL MEDIA`}</h3>
    <p>{`We collect information from third party social networking sites, including information that they
provide to Us if You use Your credentials at such social networking sites to log into some of Our
Services (such as Your name and email address to pre-populate Our sign-up form). The information You
allow Us to access varies by social networking site, and depends on the level of privacy settings
You have in place at the social networking site. You can control and find out more about these
privacy settings at the applicable social networking site.`}</p>
    <h3>{`3.4 INFORMATION WE AQUIRE FROM OTHER SOURCES`}</h3>
    <p>{`We also collect the following information from other sources:`}</p>
    <ul>
      <li parentName="ul">{`Information You provide to Us at seminars or to Our partners;`}</li>
      <li parentName="ul">{`Information You provide to Us in surveys;`}</li>
      <li parentName="ul">{`Information that is publicly available; and`}</li>
      <li parentName="ul">{`Information You consent to Us receiving from third parties.`}</li>
    </ul>
    <p>{`We also collect information that You may provide during Your support conversations with HostJane staff,
which We may monitor or record. We may combine any information We collect about You with other information
We have about You to help Us update, expand and analyze Our records, identify new customers, and provide
products and Services that may be of interest to You. If You provide Us personal information about others,
or if others give Us Your information, We will only use that information for the specific reason for which
it was provided to Us.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      